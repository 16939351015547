import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import WorksText from "./WorksText";
import Masonry from "react-masonry-css";

const WORKS = gql`
  query WorksQuery {
    works(first: 10000) {
      edges {
        node {
          title
          worksData {
            title
            shortDescription
            location
            description
            credits
            services
            featuredImage {
              sourceUrl
            }
            secondaryImage {
              sourceUrl
            }
            aspectRatio
            imagesGrid {
              images {
                sourceUrl
              }
              aspectRatio
            }
          }
        }
      }
    }
  }
`;

const WorkArchive = ({ limitToShow }) => {
  const { loading, error, data } = useQuery(WORKS);
  const [filter, setFilter] = useState("");

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage error={error} />;

  const filteredData = getFilteredData(data, filter);
  const subtitles = getSubtitles(data);

  const displayedData = limitToShow ? filteredData.slice(0, 8) : filteredData;

  return (
    <div>
      <SubtitleButtons
        subtitles={subtitles}
        setFilter={setFilter}
        currentFilter={filter}
      />

      <WorksGrid data={displayedData} />
    </div>
  );
};

const LoadingSpinner = () => (
  <div className="flex items-center justify-center ">
    <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
  </div>
);

const ErrorMessage = ({ error }) => <p>Error: {error.message}</p>;

const getFilteredData = (data, filter) =>
  filter
    ? data.works.edges.filter((edge) =>
        edge.node.worksData.services.includes(filter)
      )
    : data.works.edges;

const getSubtitles = (data) => {
  const allServices = data.works.edges.flatMap((edge) => {
    if (typeof edge.node.worksData.services === "string") {
      return edge.node.worksData.services.split(", ");
    } else if (Array.isArray(edge.node.worksData.services)) {
      return edge.node.worksData.services;
    } else {
      return [];
    }
  });
  return [...new Set(allServices)];
};

const SubtitleButtons = ({ subtitles, setFilter, currentFilter }) => {
  const [selectedSubtitle, setSelectedSubtitle] = useState("");

  const handleSubtitleClick = (subtitle) => {
    if (selectedSubtitle === subtitle) {
      setSelectedSubtitle("");
      setFilter("");
    } else {
      setSelectedSubtitle(subtitle);
      setFilter(subtitle);
    }
  };

  return (
    <div className="flex flex-wrap mb-4">
      {/* Bottone "All" per resettare il filtro (visibile solo da mobile) */}
      <button
        className={`px-4 py-2 my-1 mr-2 text-13 text-black transition duration-300 border border-black rounded-full font-favorit md:hidden ${
          currentFilter === ""
            ? "bg-fab-yellow"
            : "bg-white hover:bg-fab-yellow"
        }`}
        onClick={() => {
          setSelectedSubtitle("");
          setFilter("");
        }}
      >
        All
      </button>
      {subtitles.map((subtitle) => (
        <button
          key={subtitle}
          className={`px-4 py-2 my-1 mr-2 text-13 text-black transition duration-300 border border-black rounded-full font-favorit ${
            selectedSubtitle === subtitle
              ? "bg-fab-yellow"
              : "bg-white hover:bg-fab-yellow"
          }`}
          onClick={() => handleSubtitleClick(subtitle)}
        >
          {subtitle}
        </button>
      ))}
    </div>
  );
};

const WorksGrid = ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1024: 2,
  };

  const getAspectRatioClass = (aspectRatioString) => {
    const aspectRatioMap = {
      "4:5": "aspect-w-4 aspect-h-5",
      "1:1": "aspect-w-1 aspect-h-1",
      "3:2": "aspect-w-3 aspect-h-2",
      "16:9": "aspect-w-16 aspect-h-9",
    };

    return aspectRatioMap[aspectRatioString] || "";
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="masonry-grid"
      columnClassName="masonry-grid-column"
    >
      {data.map((edge, index) => {
        const ImageClass = getAspectRatioClass(edge.node.worksData.aspectRatio);

        return (
          <div key={index} className="masonry-item">
            <Link
              to={`/works/${edge.node.worksData.title}`}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div
                className={`${ImageClass} w-full h-auto mb-2 relative group`}
              >
                {/* Featured Image */}
                <img
                  src={edge.node.worksData.featuredImage.sourceUrl}
                  alt={edge.node.title}
                  className="absolute top-0 left-0 z-10 object-cover object-center w-full h-full transition-opacity duration-300 group-hover:opacity-0"
                />
                {/* Secondary Image */}
                <img
                  src={edge.node.worksData.secondaryImage.sourceUrl}
                  alt={`Secondary for ${edge.node.title}`}
                  className="absolute top-0 left-0 object-cover object-center w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-100"
                />
              </div>
              <div className="flex items-center justify-between ">
                <span className="mr-2 text-13 font-favorit">
                  {edge.node.title}
                </span>
                <span className="text-13 font-favorit">
                  {edge.node.worksData.location}
                </span>
              </div>
              <p className="mb-5 text-gray-400 text-13 font-favorit">
                {edge.node.worksData.shortDescription}
              </p>
            </Link>
          </div>
        );
      })}
    </Masonry>
  );
};

export default WorkArchive;
