import React from "react";
import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";

const WORKS_TEXT = gql`
  query WorksTextQuery {
    worksText {
      edges {
        node {
          workstextData {
            row
          }
        }
      }
    }
  }
`;

function WorksText() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const { data, loading, error } = useQuery(WORKS_TEXT);

  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );

  if (error) return <p>Error: {error.message}</p>;

  const { row } = data.worksText.edges[0].node.workstextData;

  return (
    <div>
      <span
        className={`${
          isLoaded ? "animate-fade-in-up" : ""
        } mb-6 md:text-28 text-22 font-favorit`}
        style={{ lineHeight: "1.1" }}
      >
        {row}
      </span>
    </div>
  );
}

export default WorksText;
