import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function SeeWorkSlider() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const generateText = (text, count) => {
    return Array.from({ length: count }).map((_, index) => (
      <div key={index} className="marquee-content-last">
        {text}
      </div>
    ));
  };

  const textToSlide =
    "See all work ✷ See all work ✷ See all work ✷ See all work ✷";

  return (
    <div className="py-8">
      <Link to="/works" onClick={() => window.scrollTo(0, 0)}>
        <button
          className={`relative w-full overflow-hidden rounded-full py-2 bg-fab-yellow ${
            loaded ? "animate-fadeIn" : ""
          }`}
        >
          <div className="flex text-black whitespace-nowrap text-13 marquee font-favorit">
            {generateText(textToSlide, 2000)}
          </div>
        </button>
      </Link>
    </div>
  );
}

export default SeeWorkSlider;
