import React from "react";
import WorkArchive from "../components/WorkArchive";
import WorksText from "../components/WorksText";
import { useState, useEffect } from "react";

function Works() {
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Puoi regolare la durata del preloader
  }, []);

  if (!isPageLoaded) {
    // Mostra il preloader fino a quando la pagina non è stata caricata completamente
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading...</p>
      </div>
    );
  }
  return (
    <div className="p-4 mx-auto overflow-x-hidden ">
      <div className="flex w-full mt-12 md:w-1/2 md:justify-self-start ">
        <WorksText />
      </div>
      <div className="mt-5 mb-28">
        <WorkArchive limitToShow={false} />
      </div>
    </div>
  );
}

export default Works;
