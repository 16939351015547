import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

function Navbar() {
  // create a function to handle is Online or is Offline based on specific hours

  function isOnline() {
    const date = new Date();
    const day = date.getDay();

    // Se è sabato o domenica, mostra "Offline"
    if (day === 0 || day === 6) {
      return renderButton("offline");
    }

    // Nei giorni feriali, mostra "Online"
    return renderButton("online");
  }

  // Funzione helper per evitare ripetizioni
  function renderButton(status) {
    const isOnline = status === "online";

    return (
      <Link to="/" onClick={() => window.scrollTo(0, 0)}>
        <button className="flex items-center justify-center  px-2 md:px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-button hover:bg-fab-yellow rounded-full font-favorit">
          <span
            className={`inline-block w-2 h-2 mr-1 align-top ${
              isOnline ? "bg-fab-green" : "bg-red-500"
            } rounded-full`}
          ></span>
          {isOnline ? "Fab is Online" : "Fab is Offline"}
        </button>
      </Link>
    );
  }

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <nav
      className={`fixed w-full z-50 flex justify-between  px-4 py-4  ${
        loaded ? "animate-fade-in-down" : ""
      }`}
    >
      {/* Bottoni a sinistra */}
      <div className="flex md:space-x-4 ">
        {isOnline()}
        <Link to="mailto:info@fabriziomorra.com" className="hidden md:flex">
          <button className="flex items-center px-2 md:px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-button hover:bg-fab-yellow rounded-full  font-favorit">
            info@fabriziomorra.com
          </button>
        </Link>
        <Link
          onClick={() =>
            window.open("https://www.instagram.com/fabrizio.morra/", "_blank")
          }
          className="hidden md:flex"
        >
          <button className="flex items-center px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-button hover:bg-fab-yellow rounded-full  font-favorit">
            @fabrizio.morra
          </button>
        </Link>
      </div>

      {/* Bottoni a destra */}
      <div className="flex space-x-4">
        <Link
          to="/works"
          className="flex items-center justify-center px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-button hover:bg-fab-yellow rounded-full  font-favorit"
          onClick={() => window.scrollTo(0, 0)}
        >
          Work
        </Link>
        <Link
          to="/about"
          className="flex items-center justify-center px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-button hover:bg-fab-yellow rounded-full  font-favorit"
          onClick={() => window.scrollTo(0, 0)}
        >
          About
        </Link>
        <Link
          onClick={() =>
            window.open("https://www.instagram.com/egg_doodle/", "_blank")
          }
          className="hidden md:flex items-center justify-center px-4 py-1.5 text-13 text-black transition duration-300 bg-fab-yellow hover:bg-fab-button rounded-full font-favorit"
        >
          @egg_doodle
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
