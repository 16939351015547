import React, { useState, useEffect } from "react";

function TextSlider() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const generateText = (text, count) => {
    return Array.from({ length: count }).map((_, index) => (
      <div key={index} className="marquee-content">
        {text}
      </div>
    ));
  };

  const textToSlide =
    "Deadlines are for dead people ✷ Award Winning TroubleMaker ✷";

  return (
    <button
      className={`relative w-full overflow-hidden rounded-full py-2 bg-fab-yellow ${
        loaded ? "animate-fadeIn" : ""
      }`}
    >
      <div className="flex text-black whitespace-nowrap text-13 marquee font-favorit">
        {generateText(textToSlide, 2000)}
      </div>
    </button>
  );
}

export default TextSlider;
