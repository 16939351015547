import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";

const LINKS_QUERY = gql`
  query LinksQuery {
    links {
      edges {
        node {
          linksData {
            newBusiness
            jobInternship
            instagram
            behance
            linkedin
            resume {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

const FOOTER_QUERY = gql`
  query FooterQuery {
    footerText {
      edges {
        node {
          footertextData {
            row1
            title
            row2
          }
        }
      }
    }
  }
`;

function Footer() {
  const { data, loading, error } = useQuery(LINKS_QUERY);
  const {
    data: footerData,
    loading: footerLoading,
    error: footerError,
  } = useQuery(FOOTER_QUERY);

  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  if (error) return <p>Error: {error?.message}</p>;

  if (footerLoading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );
  if (footerError) return <p>Error: {footerError?.message}</p>;

  const dataLinks = data.links.edges[0].node.linksData;
  const dataFooter = footerData.footerText.edges[0].node.footertextData;

  return (
    <footer className="text-white bg-black ">
      <div className="hidden grid-cols-2 gap-8 p-4 md:grid-cols-2 md:grid ">
        {/* Colonna di sinistra */}

        <div className="mt-8">
          <div className="mb-4">
            <span className="text-13 font-favorit">New Business</span>
            <br />
            <Link
              to={dataLinks.newBusiness}
              className="text-gray-400 text-13 hover:text-fab-yellow"
            >
              {dataLinks.newBusiness}
            </Link>
          </div>
          <div className="mb-4">
            <span className="text-13 font-favorit">Job & Internship</span>
            <br />
            <Link
              to={dataLinks.jobInternship}
              className="text-gray-400 text-13 hover:text-fab-yellow"
            >
              {dataLinks.jobInternship}
            </Link>
          </div>
          <div className="mb-4">
            <span className="text-13">Contact</span>
            <Link to={dataLinks.instagram}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Instagram
              </p>
            </Link>
            <Link to={dataLinks.behance}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Behance
              </p>
            </Link>
            <Link to={dataLinks.linkedin}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow">
                LinkedIn
              </p>
            </Link>
            <Link to={dataLinks.resume.mediaItemUrl}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Resume
              </p>
            </Link>
          </div>
        </div>

        {/* Colonna dei contatti */}

        <div className="mt-8 md:w-1/2">
          <p className="mb-4 text-13 font-favorit">{dataFooter.row1}</p>
          <span className="block text-13 font-favorit">{dataFooter.title}</span>
          <p className="text-gray-400 text-13 font-favorit">
            {dataFooter.row2}
          </p>
        </div>
      </div>
      <div className="grid-cols-2 gap-8 p-4 md:grid-cols-2 md:hidden">
        {/* Colonna di sinistra */}

        <div className="mt-8">
          <div className="mb-4">
            <span className="text-13 font-favorit">New Business</span>
            <br />
            <Link
              to={dataLinks.newBusiness}
              className="text-gray-400 text-13 hover:text-fab-yellow"
            >
              {dataLinks.newBusiness}
            </Link>
          </div>
          <div className="mb-4">
            <span className="text-13 font-favorit">Job & Internship</span>
            <br />
            <Link
              to={dataLinks.jobInternship}
              className="text-gray-400 text-13 hover:text-fab-yellow"
            >
              {dataLinks.jobInternship}
            </Link>
          </div>
          <div className="mb-4">
            <span className="text-13">Contact</span>
            <Link to={dataLinks.instagram}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Instagram
              </p>
            </Link>
            <Link to={dataLinks.behance}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Behance
              </p>
            </Link>
            <Link to={dataLinks.linkedin}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow">
                LinkedIn
              </p>
            </Link>
            <Link to={dataLinks.resume.mediaItemUrl}>
              <p className="text-gray-400 text-13 hover:text-fab-yellow ">
                Resume
              </p>
            </Link>
          </div>
        </div>

        {/* Colonna dei contatti */}

        <div className="mt-8 mb-8 border-t border-white md:w-1/2 ">
          <p className="mt-6 mb-4 text-13 font-favorit">{dataFooter.row1}</p>
          <span className="block text-13 font-favorit">{dataFooter.title}</span>
          <p className="text-gray-400 text-13 font-favorit">
            {dataFooter.row2}
          </p>
        </div>
      </div>

      <div className="w-full mt-5 mb-5 border-t border-white">
        <p className="hidden py-4 text-center text-13 font-favorit md:block">
          © 2023 All rights reserved apart from the rights of having fun ⚽️
        </p>
        <p className="py-4 text-center text-13 font-favorit md:hidden">
          © 2023 All rights reserved apart from <br /> the rights of having fun
          ⚽️
        </p>
      </div>
    </footer>
  );
}

export default Footer;
