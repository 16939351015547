// components/WorkSlider.js

import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import Slider from "react-slick";

const GET_WORK_IMAGES = gql`
  query NewQuery {
    homeSliders {
      edges {
        node {
          homesliderData {
            mainImages {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

function WorkSlider() {
  const { data, loading, error } = useQuery(GET_WORK_IMAGES);
  const [images, setImages] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (data && data.homeSliders.edges.length > 0) {
      const images =
        data.homeSliders.edges[0].node.homesliderData.mainImages.map(
          (img) => img.sourceUrl
        );
      setImages(images);
    }
  }, [data]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const settings = {
    infinite: true,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className={`w-full mt-16 ${loaded ? "animate-fadeIn" : ""}`}>
      <Slider {...settings}>
        {images.map((img, index) => (
          <div
            key={index}
            className="flex justify-center items-center h-[600px] w-full overflow-hidden"
          >
            <img
              src={img}
              alt={`Work ${index + 1}`}
              className="object-cover object-center w-full h-full"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default WorkSlider;
