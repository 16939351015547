import React from "react";
import { useQuery, gql } from "@apollo/client";
import Slider from "react-slick";
import { useState, useEffect, useRef } from "react";

const ABOUT_QUERY = gql`
  query NewQuery {
    abouts {
      edges {
        node {
          aboutData {
            images {
              sourceUrl
            }
            textRow1
            textRow2
            textRow3
            textRow4
            selectedClients
            services
            experiences {
              agency
              role
              flag
              from
              to
            }
            teachingtalks {
              year
              title
              location
            }
          }
        }
      }
    }
  }
`;

function About() {
  const { data, loading, error } = useQuery(ABOUT_QUERY);
  const [loaded, setLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Puoi regolare la durata del preloader
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // This ensures the animation is only applied once
        }
      },
      {
        threshold: 0.1, // Adjust this if you want more or less of the element to be visible before triggering
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!isPageLoaded) {
    // Mostra il preloader fino a quando la pagina non è stata caricata completamente
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading...</p>
      </div>
    );
  }

  // create a loader component
  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );

  const {
    images,
    experiences,
    selectedClients,
    services,
    teachingtalks,
    textRow1,
    textRow2,
    textRow3,
    textRow4,
  } = data.abouts.edges[0].node.aboutData;
  const settings = {
    infinite: true,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="p-4 mx-auto ">
      <div className="flex flex-wrap gap-8 mb-4 mt-14 md:flex-nowrap ">
        <div className={`w-full md:w-1/2 ${loaded ? "animate-fadeIn" : ""}`}>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div
                key={index}
                className="flex items-center justify-center overflow-hidden aspect-w-1 aspect-h-1"
              >
                <img
                  src={image.sourceUrl}
                  alt={`Work ${index + 1}`}
                  className="object-cover object-center aspect-content"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div
          ref={elementRef}
          className={`w-full  md:w-1/2 ${isVisible ? "animate-fadeIn" : ""}`}
        >
          <p
            className="mb-6 text-22 md:text-28 font-favorit"
            style={{ lineHeight: "1.1" }}
          >
            {textRow1}
          </p>
          <p
            className="mb-6 text-22 md:text-28 font-favorit"
            style={{ lineHeight: "1.1" }}
          >
            {textRow2}
          </p>
          <p
            className="mb-6 text-22 md:text-28 font-favorit"
            style={{ lineHeight: "1.1" }}
          >
            {textRow3}
          </p>
          <p
            className="mb-6 text-22 md:text-28 font-favorit"
            style={{ lineHeight: "1.1" }}
          >
            {textRow4}
          </p>
        </div>
      </div>

      <div
        ref={elementRef}
        className={`flex flex-wrap mb-12 md:flex-nowrap font-favorit ${
          isVisible ? "animate-fadeIn" : ""
        }`}
      >
        <div className="w-1/3 md:w-1/5 ">
          <p className="text-13 md:text-base ">Experiences</p>
        </div>
        <div className="w-3/5 space-y-4 text-13 ">
          {experiences.map((exp, index) => (
            <p key={index}>
              {exp.agency} - {exp.role} {exp.flag} <br /> {exp.from} → {exp.to}
            </p>
          ))}
        </div>
      </div>
      <div
        ref={elementRef}
        className={`flex flex-wrap mb-12 md:flex-nowrap font-favorit ${
          isVisible ? "animate-fadeIn" : ""
        }`}
      >
        <div className="w-1/3 md:w-1/5 ">
          <p className="text-13 md:text-base">Selected Clients</p>
        </div>
        <div className="w-3/5 space-y-4 text-13 ">
          <p>{selectedClients}</p>
        </div>
      </div>
      <div
        ref={elementRef}
        className={`flex flex-wrap mb-12 md:flex-nowrap font-favorit ${
          isVisible ? "animate-fadeIn" : ""
        }`}
      >
        <div className="w-1/3 md:w-1/5 ">
          <p className="text-13 md:text-base">Services</p>
        </div>
        <div className="w-3/5 text-13">
          {services.split(",").map((service, index) => (
            <p key={index}>{service.trim()}</p>
          ))}
        </div>
      </div>
      <div
        ref={elementRef}
        className={`flex flex-wrap mb-12 md:flex-nowrap font-favorit ${
          isVisible ? "animate-fadeIn" : ""
        }`}
      >
        <div className="w-1/3 md:w-1/5 ">
          <p className="text-13 md:text-base">Teaching & Talks</p>
        </div>
        <div className="w-3/5 text-13">
          {teachingtalks.map((teachingtalks, index) => (
            <p key={index}>
              {teachingtalks.year} - {teachingtalks.title} -{" "}
              {teachingtalks.location}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
export default About;
