import React from "react";
import WorkSlider from "../components/WorkSlider";
import TextSlider from "../components/TextSlider";
import SeeWorkSlider from "../components/SeeWorkSlider";
import WorkArchive from "../components/WorkArchive";
import HomeText from "../components/HomeText";
import { useState, useEffect } from "react";

function Homepage() {
  const [isPageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Puoi regolare la durata del preloader
  }, []);

  if (!isPageLoaded) {
    // Mostra il preloader fino a quando la pagina non è stata caricata completamente
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
        <p className="mt-4 text-gray-600">Loading...</p>
      </div>
    );
  }
  return (
    <div className="p-4 py-0 mx-auto overflow-x-hidden ">
      <WorkSlider />
      <div className="flex justify-center mt-8">
        <TextSlider />
      </div>
      <div className="flex mt-8 justify-self-start">
        <HomeText />
      </div>
      <div className="mt-5">
        <WorkArchive limitToShow={true} />
      </div>
      <div className="mt-8 ">
        <SeeWorkSlider />
      </div>
    </div>
  );
}

export default Homepage;
