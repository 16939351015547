import React from "react";
import { useQuery, gql } from "@apollo/client";

const HOME_QUERY = gql`
  query HomeQuery {
    homeTexts {
      edges {
        node {
          hometextData {
            riga1
            riga2
          }
        }
      }
    }
  }
`;

function HomeText() {
  const { data, loading, error } = useQuery(HOME_QUERY);

  if (loading)
    return (
      <div className="flex items-center justify-center ">
        <div className="w-16 h-16 border-b-2 border-gray-900 rounded-full animate-spin"></div>
      </div>
    );

  if (error) return <p>Error: {error.message}</p>;

  const { riga1, riga2 } = data.homeTexts.edges[0].node.hometextData;

  return (
    <div>
      <h2
        className="mb-6 space-y-0 text-28 font-favorit md:w-3/5"
        style={{ lineHeight: "1.1" }}
      >
        {riga1}
      </h2>
      <h2 className="mb-1 text-28 font-favorit" style={{ lineHeight: "1.1" }}>
        {riga2}
      </h2>
    </div>
  );
}

export default HomeText;
