import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Works from "./pages/Works";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import WorkDetail from "./pages/WorkDetail";
import ReactGA from "react-ga";
import { useEffect } from "react";

const client = new ApolloClient({
  uri: "https://fab.fabriziomorra.com/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/works" element={<Works />} />
          <Route path="/works/:id" element={<WorkDetail />} />
          <Route path="/about" element={<About />} />
          {/* Altre rotte */}
        </Routes>
        <Footer />
      </Router>
    </ApolloProvider>
  );
}

export default App;
